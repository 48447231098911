<template>
  <div class="chat-window">
    <div class="messages" ref="chatBox">
      <div v-for="message in messages.slice().reverse()" :key="message.id" :class="{'message': true, 'user-message': message.isUser, 'other-user-message': !message.isUser}">
        <span  style="white-space:pre-line;" v-html="parseMarkdownToHtml(message.text)"></span>
      </div>
    </div>
    <input v-model="input" @keyup.enter="sendMessage" placeholder="Type a message..." class="message-input" />
    <div class="mic-buttons">
      <!-- To handle the controls -->
      <button v-if="!isListening" @click="start">
        Speak
      </button>
      <button v-if="isListening" class="orange" @click="stop();sendMessage()">
        Stop
      </button>
    </div>


  </div>
</template>

<script>

/* eslint-disable */
import { io } from "socket.io-client";
import {useSpeechRecognition} from './useSpeechRecognition'

export default {
  name: 'ChatWindow',

  data() {
    return {
      messages: [],
      input: "",
      isUserTurn: true,
      socket: null,
      transcription:'',
      record: false,
      play: false,
      results: null,
      myThread:""
    };
  },
  setup(){

    //  Calling the composable and passing a parameter
    const { isListening, isSupported, stop, result,start,error } = useSpeechRecognition({
      lang:'en-US',
      continuous: true,
      interimResults : true,
    })
    return {isListening,isSupported,stop,input:result,start,error}
  },
  mounted() {


    this.scrollToBottom();
    this.socket = io("https://chatbotapi.qoo.studio/");

    this.socket.on('myThread',(response) => {
      this.myThread = response;
    });

    this.socket.on("response", (response) => {
      console.log("response",response)
      this.isUserTurn =false
      this.messages.reverse().push({
        id: this.messages.reverse().length + 1,
        text: response,
        isUser: this.isUserTurn
      });
      this.scrollToBottom();
    });
  },
  methods: {
      parseMarkdownToHtml(markdown) {
          // Convert image markdown to HTML img tag
          let html = markdown.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, '<img style="width: 400px;" src="$2" alt="$1">');

          html = html.replace(/\[Unit Image Link]\(([^)]+)\)/g, '<img style="width: 400px;" src="$1">');
          html = html.replace(/\[Image Link]\(([^)]+)\)/g, '<img style="width: 400px;" src="$1">');
          html = html.replace(/\[Link]\(([^)]+)\)/g, '<img style="width: 400px;" src="$1">');
          html = html.replace(/\[Floor Plan Image]\(([^)]+)\)/g, '<img style="width: 400px;" src="$1">');

          // Convert link markdown to HTML a tag
          html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
          return html;
      },
    scrollToBottom() {

      const chatBox = this.$refs.chatBox;
      console.log(chatBox.scrollHeight)
      chatBox.scrollTop = chatBox.scrollHeight+"px";
      chatBox.scrollTo({top: chatBox.scrollHeight+"px"});
      setTimeout(()=>{
        chatBox.scrollTop = chatBox.scrollHeight+"px";
      },100)
    },
    sendMessage() {
      this.scrollToBottom();
      console.log('this.myThread',this.myThread)
      if (this.input.trim()) {
        this.socket.emit("message", this.myThread, this.input);
        this.isUserTurn =true
        this.messages.push({
          id: this.messages.length + 1,
          text: this.input,
          isUser: this.isUserTurn
        });
        this.input = '';
        this.scrollToBottom();
        // Add your API call or logic here to send the message to ChatGPT
      }
    }
  }
}
</script>

<style>
.chat-window {
  max-width: 600px;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 500px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
    display: flex;
    flex-direction: column-reverse;
}

.message {
  padding: 8px 15px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.user-message {
  background-color: #e0f7fa;
  margin-left: auto;
  text-align: right;
}

.other-user-message {
  background-color: #fff9c4;
  margin-right: auto;
  text-align: left;
}

.message-input {
  border: none;
  padding: 15px;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}
</style>
